const IconNavigate = (props) => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill={props.fill}
      stroke={props.stroke}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 6.50001L16 1.50001M16 1.50001H11M16 1.50001L8.5 9M6.83333 1.5H5C3.59987 1.5 2.8998 1.5 2.36502 1.77248C1.89462 2.01217 1.51217 2.39462 1.27248 2.86502C1 3.3998 1 4.09987 1 5.5V12.5C1 13.9001 1 14.6002 1.27248 15.135C1.51217 15.6054 1.89462 15.9878 2.36502 16.2275C2.8998 16.5 3.59987 16.5 5 16.5H12C13.4001 16.5 14.1002 16.5 14.635 16.2275C15.1054 15.9878 15.4878 15.6054 15.7275 15.135C16 14.6002 16 13.9001 16 12.5V10.6667"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconNavigate
